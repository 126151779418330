export default defineNuxtRouteMiddleware((to, from) => {
  const cartStore = useCartStore();

  if (to.path === generateLink('/checkout') && cartStore.cartUUID === null) {
    return navigateTo(generateLink('/'));
  }
  if (to.path === generateLink('/checkout/order-confirmation') && !to.query.order) {
    return navigateTo(generateLink('/'));
  }
});
